<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Addons</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Addons"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.addonForm.openForm()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Addon</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  class="mr-2"
                  v-on="on"
                  :to="{ name: 'module-watson-product-addons-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Addons</span>
            </v-tooltip>
            <v-btn depressed light :to="{ name: 'module-watson-products' }">
              <v-icon small left>mdi-arrow-left</v-icon> Back to Products
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="addons"
        no-data-text="There are currently no addons"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.addonForm.openForm(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDeleteAddon(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <AddonForm ref="addonForm" />
    <v-dialog v-model="deleteAddon.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Addon</v-card-title>
        <v-card-text
          >Are you sure you wish to delete
          {{ deleteAddon.addon.name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDeleteAddon"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteAddon.loading"
            @click="saveDeleteAddon"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AddonForm from "./components/AddonForm";

export default {
  components: {
    AddonForm,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Products",
          disabled: false,
          exact: true,
          to: {
            name: "module-watson-products",
          },
        },
        {
          text: "Addons",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteAddon: {
        dialog: false,
        addon: {},
        loading: false,
      },
    };
  },

  computed: {
    addons() {
      let addons = this.$store.state.watson.products["addons"];

      if (this.searchTerm !== "") {
        addons = addons.filter(a => {
          const name = a.name.toLowerCase();
          return name.includes(this.searchTerm.toLowerCase());
        });
      }

      return addons;
    },
  },

  methods: {
    openDeleteAddon(addon) {
      this.deleteAddon.addon = addon;
      this.deleteAddon.dialog = true;
    },

    resetDeleteAddon() {
      this.deleteAddon.dialog = false;
      this.deleteAddon.addon = {};
      this.deleteAddon.loading = false;
    },

    saveDeleteAddon() {
      this.deleteAddon.loading = true;

      this.$store
        .dispatch("watson/products/deleteAddon", {
          appId: this.$route.params.id,
          addonId: this.deleteAddon.addon.id,
        })
        .then(() => {
          this.resetDeleteAddon();
        })
        .catch(() => {
          this.deleteAddon.loading = false;
        });
    },
  },
};
</script>
